import React from "react";
import { viewports, colors } from "../../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller } = viewports;
const { white, black } = colors;

const BigBlockStyles = () => (
  <style jsx>{`
    .custom-car-item img {
      border-radius: 21px !important;
    }
    .custom-car-item .gatsby-image-wrapper img {
      border-radius: 21px;
      margin: 0 1.5rem;
    }
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(calc(-460px * 10));
      }
    }
    .why-slide-track {
      animation: scroll 40s linear infinite;
    }
    .big-block {
      position: relative;
    }
    .big-cont {
      position: relative;
      overflow: hidden;
      height: 500px;
      margin-bottom: 150px;
    }
    .big-cont:nth-child(2) {
      bottom: -100px;
    }
    @media ${mdOrSmaller} {
      .big-block {
        width: 100%;
      }
      .big-intro {
        margin: 0 32px;
      }
      .big-cont {
        height: 500px;
        margin: 16px;
      }
      .big-img {
        position: relative !important;
        width: 100vw;
        height: 500px;
      }
      .big-cont:nth-child(2) {
        bottom: 0 !important;
      }
      #custom-slide {
        margin-top: 0 !important;
        object-fit: cover !important;
        border-radius: 21px;
      }
      #custom-slide .gatsby-image-wrapper img {
        border-radius: 21px !important;
      }
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-273px * 10));
        }
      }
    }
    @media ${smOrSmaller} {
      .big-block {
        padding: 64px 0 64px 0;
      }
      .big-p-margined {
        margin-right: 0;
      }
      .big-title {
        font-weight: 800 !important;
        font-size: 32px;
        white-space: normal;
        color: #fff;
      }
    }
  `}</style>
);

export default BigBlockStyles;

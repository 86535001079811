import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import Carousel from "nuka-carousel";
import Image from "../../ImageQuerys/RandomImages";
import BigBlockStyles from "./BigBlockStyles";
import useWindowSize from "../../../hooks/use-window-size";

const titleVar = {
  show: {
    transition: {
      staggerChildren: 0.35,
    },
  },
};

const item = {
  hidden: {
    opacity: 0,
    y: 80,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

const BigBlockImgs = (props) => {
  const { sectionData, bgDark } = props;
  const repeat = [...sectionData.content, ...sectionData.content];
  const controls = useAnimation();
  const titleControls = useAnimation();
  const { ref, inView } = useInView();
  const [numberOfSlides, setNumberOfSlides] = useState(4);
  const size = useWindowSize();
  const including = [1, 4, 6, 9, 11, 14, 16, 19];
  useEffect(() => {
    if (inView) {
      titleControls.start("show");
      setTimeout(() => {
        controls.start("animate");
      }, 2000);
    }
    if (!inView) {
      controls.start("exit");
    }
    const { width } = size;
    const isSmallMobile = width <= 320;
    const isXsMobile = width > 320 && width < 375;
    const isMobile = width >= 375 && width < 576;
    const isTablet = width > 576 && width < 992;

    const slides = () => {
      if (isSmallMobile) return 1.13;
      if (isXsMobile) return 1.28;
      if (isMobile) return 1.4;
      if (isTablet) return 2.5;
      return 1.25;
    };
    setNumberOfSlides(slides);
  }, [controls, inView, size]);
  return (
    <section
      className="big-block w-full pt-[100px] lg:pt-[200px] pb-[100px] lg:pb-[300px]"
      style={{ background: `${bgDark ? "#090D12" : "#fff"}` }}
    >
      <motion.div
        ref={ref}
        initial={isMobileOnly ? "show" : "hidden"}
        animate={titleControls}
        variants={titleVar}
        className="lg:max-w-[734px] lg:mx-auto px-4 lg:px-0"
      >
        <motion.h2
          variants={item}
          className="text-h2-small text-white font-bold mb-8"
        >
          {sectionData.introTitle}
          <span className="text-primary">.</span>
        </motion.h2>
        <motion.p
          variants={item}
          className="text-p lg:text-p-large text-black-600 mb-10"
        >
          {sectionData.introSub}
        </motion.p>
      </motion.div>
      <div
        className="m-auto overflow-hidden relative w-full h-auto hidden lg:block"
        id="why-slider"
      >
        <div
          className="flex w-[calc(273px*20)] lg:w-[calc(460px*20)] why-slide-track"
          id="why-slider-track"
        >
          {repeat.map((i, idx) => (
            <div
              key={`carousel_item_${idx.toString()}`}
              className={`w-[273px] h-[273px] lg:h-[471px] lg:w-full mx-4 lg:mx-9 rounded-[21px] ${
                idx % 2 ? "mt-0" : "mt-12"
              } ${including.includes(idx) ? "lg:w-[460px]" : "lg:w-[322px]"}`}
              id="custom-slide"
            >
              <Image
                imageName={i}
                className="w-full h-full lg:h-[471px] custom-car-item rounded-[21px] "
                objectFit="cover"
                style={{ borderRadius: 21 }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="block lg:hidden">
        <Carousel
          cellAlign="center"
          cellSpacing={0}
          slidesToShow={numberOfSlides}
          wrapAround
          withoutControls
        >
          {repeat.map((i, idx) => (
            <Image
              imageName={i}
              style={{ borderRadius: 21 }}
              className="w-[273px] h-[273px] rounded-[21px] rounded-[21px] custom-car-item"
            />
          ))}
        </Carousel>
      </div>
      <BigBlockStyles />
    </section>
  );
};

BigBlockImgs.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  bgDark: PropTypes.bool,
};
BigBlockImgs.defaultProps = {
  sectionData: null,
  bgDark: true,
};

export default BigBlockImgs;
